.eMed_dialogHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eMed_cancel {
  cursor: pointer;
}

.eMed_dialogBody {
  display: flex;
  flex-direction: column;
}

.eMed_dialogbodyContent {
  display: flex;
  justify-content: center;
}

#eMed_dlgBtn {
  display: flex;
  justify-content: center;
}

#eMed_Dialog_title {
  font-size: 1.2vw;
}