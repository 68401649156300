:root {
    --gray: #616161;
  }

.eMed_card_header {
    height: 6.3vh;
}

.eMed_card_div {
    padding: 0.2vw;
    border-radius: 0.3vw;
    box-shadow: 0px 0px 3px var(--gray);
}

#eMed_card_img {
    width: 3vw;
    height: 3vw;
}

#eMed_card_lblTxt {
    font-size: 0.8vw;
}

#eMed_card_cntTxt {
    font-size: "0.8vw";
    font-weight: 600;
}

#eMed_DocRepo_Container {
    padding: 0.625vw;
}

.eMed_DocRepo_Box {
    height: 80vh;
}

.eMed_DocRepo_mainDiv {
    height: 74vh;
}

.eMed_DocRepo_Div {
    height: 70vh;
}

.eMed_DocRepo_rightHead {
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eMed_DocRepo_rightHead_txt {
    font-weight: 600;
    margin-left: 1.5vw;
    color: #616161;
}

.eMed_DocRepo_subDiv {
    padding: 0.625vw;
    height: 59.8vh
}

#eMed_DocRepo_divs{
    margin: 0.5vw;
}

.eMed_DocRepo_imgDiv{
    margin: 0.7vw 0.5vw;
    width: 27vw;
    height: 34vh;
}

.eMed_DocRepo_imgSubDiv{
    width: 14vw;
    height: 10vw;
    border: 1px solid var(--gray);
    border-radius: 0.3vw;
}

#eMed_img_subTxt{
    font-size: 0.6vw;
    color: var(--gray);
}

#eMed_img_lblTxt {
    font-size: 0.9vw;
    color: var(--gray);
}

#eMed_upload_img{
    width: 2vw;
    height: 2vw;
}
#eMed_DocRepo_datePicker{
    position: relative;
    top: 0.6vw;
}

#eMed_upload_input {
    display: none;
}

.eMed_DocRepo_img {
    width: 63vw;
    height: 64vh;
}

.eMed_Testrep_Img_item {
    width: 27vw;
    height: 5vw;
    margin-top: 0.5vw;
    padding: 0.5vw;
    overflow: scroll;
    overflow-y: hidden;
}

.eMed_Testrep_Img {
    width: 3vw;
    height: 3vw;
    cursor: pointer;
}

#eMed_Testrep_Icon {
    position: relative;
    top: -4.3vw;
    left: 2.5vw;
}

#eMed_DocRepo_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    max-height: 80vh;
    margin: 0 auto;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}

#eMed_DocRepo_popupClose {
    width: 100%;
    height: 3vh;
    padding: 0.625vw;
    margin-top: 0.5vw;
}

#eMed_DocRepo_popupDiv {
    width: 70vw;
    height: fit-content;
    margin-bottom: 2vw;
}

.eMed_DocRepo_popupIcon {
    width: 1vw;
    height: 1vw;
}

.eMed_nur_container {
    background-color: #F5F8F7;
    height: 80vh;
}

.eMed_nur_container1 {
    background-color: #F5F8F7;
    height: 78vh;
}

.eMed_nur_top_card {
    height: 5.6vw;
}

.eMed_nur_grid_container {
    height: 72dvh;
}

.eMed_nur_grid_container1 {
    height: 65dvh;
}

.eMed_nur_left {
    padding-top: 0.5vw;
}

.eMed_nur_table{
    height: 70dvh;
    width: 100%;
    background-color: white;
}

.eMed_nur_right {
    padding-top: 0.5vw;
}

.eMed_nur_right_card{
    background-color: white;
    height: 70vh;
    width: 100%;
}

.eMed_nur_right_txtbox{
    width: 100%;
    height: 58.5vh;
    overflow: scroll;
}

.eMed_nur_rgt_title{
    background-color: #F5F8F7;
    width: 25vw;
    height: 2.5vw;
    padding: 0.5vw;
}

.eMed_nur_btn_card{
    background-color: #F5F8F7;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_nur_btn_one{
    margin-right: 1vw !important;
    text-transform: capitalize !important;
}

.eMed_nur_btn_two{
    text-transform: capitalize !important;
}

.eMed_nur_inner_content{
    padding-left: 1vw;
    font-size: 0.8vw !important;
    color: gray;
}

.eMed_nur_txtBox{
    width: 10vw;
}

.eMed_nur_gestTxtBox{
    width: 5dvw;
}

.eMed_nur_inputAdor{
    display: flex;
    justify-content: center;
}

.eMed_input_label{
    display: flex;
    flex: 0.35;
    margin-left: 1vw !important;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1vw;
}

.eMed_input_txtbox{
    flex: 0.65;
    margin: 1vw 1vw 0 1vw;
}

.eMed_nur_grid_one{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    margin: 0.5vw 0.5vw 0vw 1.2vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 17vw;
    padding: 0.2vw;
}

.eMed_nur_image{
    display: flex;
    justify-content: center;
}

.eMed_nur_grid_txt{
    margin-left: 0.5vw;
}

.eMed_date_picker{
    margin: 1vw 1.5vw;
    width: 15vw;
    background-color: #F5F8F7;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.eMed_nur_date_picker{
    margin: 1vw 1vw !important;
}

.eMed_temp_txt{
    width: 5.3vw;
}

.eMed_Time_Style{
    width: 22.5vw;
    margin: 1vw 0vw 0vw 1vw;
    border-top-right-radius:5px ;
    border-top-left-radius: 5px;
}

.eMed_testrp_div{
    width: 68.5vw;
    height: 2vh; 
    position: relative;
    bottom: 30vh;
}

.eMed_testrp_arrow{
    transform: translate(0, -50%);
    z-index: 1;
    cursor: pointer;
}

.eMed_testrp_arrowSize{
    font-weight: 600;
}
.eMed_Patient_Details_Long{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    margin: 0.5vw 0.5vw 0vw 1.2vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    padding: 0.2vw;
}