#eMed_Ip_Est_card{
    display: flex;
    height: 13vh;
}
.eMed_Ip_Est_AmtCrd{
    width:80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.eMed_Ip_Est_fltDiv{
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1vw;
    padding-right: 1vw;
}
#eMed_Ip_Est_head_card{
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1.5vw;
}
.eMed_Ip_Est_head_div {
    padding: 0.2vw;
    border-radius: 0.3vw;
    box-shadow: 0px 0px 3px var(--gray);
}
.eMed_Ip_Est_ptCard {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    margin-right: 0.1vw;
    overflow-wrap: break-word;
}
.eMed_Ip_Est_image {
    width: 2.8vw;
    height: 2.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDFFFE;
    border-radius: 0.3vw;
    margin-right: 0.5vw;
}
.eMed_Ip_Est_img{
    width: 1.8vw;
    height: 1.8vw;
}
.eMed_Ip_Est_details {
    flex-direction: row;
    display: flex;
    width: 17vw;
    height: 2.8vw;
    justify-content: space-between;
}
.eMed_Ip_Est_mainDiv{
    height: 67vh;
    width: 100%;
    background-color: white;
    padding: 0.65vw;
}
#eMed_Ip_Est_txt{
    font-size: 0.9vw;
    color: #616161;
    font-weight: 600;
}
.eMed_Ip_Est_btn{
    width: 1.5vw;
    height:1.5vw;
    border-radius: 50%;
    margin-right: 0.5vw;
}
.eMed_Ip_Est_Tbl{
    height: 55vh;
}
#eMed_Ip_Est_txt1{
    font-size: 0.9vw;
    font-weight: 600;
}
#eMed_Ip_Est_txt2{
    font-size: 0.8vw; 
    font-weight: 600;
}
.eMed_Ip_Est_txtbox {
    display: flex;
    margin: 0.3vw;
}
.eMed_Ip_Est_date_filter{
    width: 15vw;
    margin-top: 1.5vw;
    height: 5vh;
    position: relative;
    left: 6vw;
    margin-bottom:1.5vw;
}
.eMed_IP_Est_printimg{
    height: 1.5vw;
    width: 1.5vw;
}
.eMed_IP_Est_Search_Pop{
    margin-top: 20vh;
    margin-left: 5vw;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 50%;
  }
  .eMed_IP_Est_Search_item{
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-between;
    align-items: center;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 4vw;
  }
  .eMed_IP_Est_Dialogbox {
    padding: 15px;
    width: 30vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
