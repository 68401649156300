#emedhub_inventory_Container {
    height: 90vh;
}

#emedhub_registry_mainContainer {
    width: 100%;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.5);
}

#emedhub_registry_mainBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    height: 49vh;
    background-color: white;
    border-radius: 10px;
}

#emedhub_registry_header {
    display: flex;
    justify-content: space-between;
    height: 3vh;
    padding: 0.9vw;
    background-color: #FAF9F9;
    outline: none;
}

.emedhub_registry_subDiv {
    display: flex;
    justify-content: space-evenly;
    margin: 1.2vw;
}

#emedhub_registry_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vw;
    height: 4vw;
    padding: 1.5vw;
    background-color: #FAF9F9;
}

.emedhub_registry_footer_subdiv {
    display: flex;
    align-items: center;
    margin: -1vw;
}

.emedhub_registry_bulletin {
    width: 0.25vw;
    height: 0.25vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.emedhub_registry_footer_Text1 {
    font-size: 0.7vw;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    border-right: 0.15vw solid rgba(0, 0, 0, 0.5);
    padding: 0 0.3vw;
}

.emedhub_registry_footer_Text2 {
    font-size: 0.7vw;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 0.3vw;
}

.active_tab_container {
    max-height: 100vw;
    max-width: 100vw;
    height: 75vh;
    box-sizing: border-box;
    background-color: #F5F8F7;
}

.active_tab_header_card {
    padding: 1vw 1vw 1.2vw 0vw;
    display: flex;
    justify-content: end;
}

.active_tab_image {
    margin-right: 0.5vw;
    border-left: 2px solid gray;
    padding-left: 1vw;
}

.active_tab_table_container {
    height: 65vh;
    width: 93.7vw;
    margin-top: 0.5vw;
    background-color: white;
}

.inactive_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 20vw;
    background-color: white;
    border-radius: 10px;

}

.inactive_popup_header {
    margin: 1vw;
    display: flex;
}

.inactive_popup_textfield {
    margin: 1vw;
    height: 9vw;
}

.inactive_popup_footer {
    display: flex;
    margin: 3vw 1vw 1vw 1vw;
}

.inactive_popup_left {
    display: flex;
    flex-direction: column;
    flex: 0.6;
}

.inactive_popup_right {
    display: flex;
    flex: 0.4;
    justify-content: end;
    height: 2.5vw;
}

.inactive_span {
    margin-left: 0.5vw;
    color: #616161;
}

.inactive_popup_text {
    color: rgba(0, 0, 0, 0.5);
}

.inactive_bulletin {
    width: 0.25vw;
    height: 0.25vw;
    background-color: #616161;
    border-radius: 50%;
    position: relative;
    top: 0.8vw;
}

.inactive_popup_header_text {
    flex: 0.7;
}

.inactive_popup_close_card {
    flex: 0.3;
    text-align: end;
}

.inactive_popup_close {
    width: 1vw;
    height: 1vw;
    margin-right: 0.5vw;
    cursor: pointer;
}

.active_view_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65.5vw;
    height: 32vw;
    background-color: white;
    border-radius: 10px;
    overflow-y: scroll;
    padding-bottom: 1vw;
}

.active_view_header {
    display: flex;
    margin: 1vw;
    padding: 0.5vw;
    background-color: #F5F8F7;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
}

.active_view_header_text {
    flex: 0.7;
    font-size: 1.1vw;
    font-weight: 600;
    position: sticky;
}

.active_view_close {
    flex: 0.3;
    text-align: end;
    align-items: center;
    position: relative;
    top: 0.25vw;
}

.active_view_middle_card {
    margin: 1vw;
    height: 23vw;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column;
}

.active_view_footer_table_card {
    margin: 1vw;
    height: 15vw;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
    overflow: scroll;
}

.active_view_footer {
    display: flex;
}

.active_view_left {
    flex: 0.7;
    margin-left: 1vw;
}

.active_view_text {
    color: rgba(0, 0, 0, 0.5);
}

.active_view_right {
    flex: 0.3;
    text-align: end;
    margin-right: 1vw;
}

.active_view_textfield {
    padding: 1vw;
}

.mov_container_card {
    background-color: #F5F8F7;
    height: 75vh;
}

.mov_header_card {
    height: 4.5vw;
    padding: 1vw;
    display: flex;
    justify-content: end;

}

.mov_image_card {
    margin-right: 0.5vw;
    border-left: 2px solid gray;
    padding-left: 1vw;
    height: 2.2vw;
    cursor: pointer;
}

.mov_table_card {
    margin-top: 0.5vw;
    height: 65vh;
    background-color: #FFFFFF;
}

.super-app-theme--header {
    background-color: #616161;
}

.stock_edit_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65vw;
    height: 40.5vw;
    background-color: white;
    border-radius: 10px;
}

.stock_edit_header {
    display: flex;
    margin: 1vw;
    padding: 0.5vw;
    background-color: #F5F8F7;
    box-shadow: 0px 0px 2px gray;
    border-radius: 5px;
}

.stock_edit_header_text {
    flex: 0.7;
    font-size: 1.15vw;

}

.stock_table_container{
    height: 65vh;
     margin-top: 1vw; 
     background-color:white;
}

.stock_card{
    background-color: #F5F8F7;
    height: 75vh;
}

.stock_print_design{
    height: 2.3vw;
    width: 2.3vw;
    cursor: pointer;
}

.stock_edit_close {
    flex: 0.3;
    text-align: end;
    align-items: center;
    position: relative;
    top: 0.25vw;
    padding-right: 0.2vw;
}

.stock_edit_img_close {
    height: 1vw;
    width: 1vw;
    cursor: pointer;
}

.stock_edit_middle_card {
    border-radius: 5px;
    margin: 1vw;
    height: 17vw;
    box-shadow: 0px 0px 2px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.stock_edit_radio_card {
    margin: 1vw;
    height: 5vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    padding: 0.5vw;
}

.stock_edit_input_card {
    margin: 1vw;
    height: 7vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
}

.font {
    font-size: 0.7vw;
}

.span_font {
    font-size: 0.9vw;
}


.checkbox{
    margin-right: 1vw !important;
    width: 8vw !important;
    height: 2.3vw;
    text-transform: capitalize !important;
}

.checkbox span {
    position: relative;
    top: 0.4vw;
    color: white;
    cursor: pointer;
}

.add_product_card{
    display: flex;
    margin: auto;
    margin-left: 2vw;
    width: 82vw;
}

.add_product_flex_one{
    flex: 0.5;
}

.add_product_flex_two{
    flex: 0.5;
    display: flex;
    justify-content: end;
}

.add_product_top_header{
    display: flex;
    height: 5vh;
    background-color: #F5F8F7;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.add_product_top_header_right{
    flex: 0.5;
    align-self: center;
    padding-left: 1vw;
}

.add_product_top_header_left{
    flex: 0.5;
    display: flex;
    justify-content: end;
    padding-right: 1vw;
    align-self: center;
}

.mandatory_card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35vw;
    height: 12vw;
    background-color: white;
    border-radius: 10px;
}

.mandatory_card_header{
    background-color: #F5F8F7;
    height: 5vh;
    display: flex;
    justify-content: center;
    font-weight: 600;
    align-items: center;
    margin: 1vw;
}

.mandatory_middle_card{
    display: flex;
    justify-content: center;
    align-items: center;
}
.mandatory_middle_card_text{
    font-size: 1vw;
    font-weight: 700;
    color: green;
}

.mandatory_card_footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vw;
}

.add_button{
    position: absolute;
    top: 17vw;
    left: 60vw;
}

.minus_button{
    position: absolute;
    top: 17vw;
    left: 48vw;
}

.eMed_upload{
    height: 2.2vw;
    cursor: pointer;
}

.eMed_pro_name{
    margin-left: 0.5vw;
}

.eMed_pro_name > span{
    color: red;
}

.eMed_pause{
    margin-right: 1vw;
}

.eMed_date_picker{
    position: relative;
    top: -1vw;
    width: 0vw !important;
    right: 10vw;

}