.eMed_pharma_settings_Main{
    height: 75vh;
    border-radius: 5px;
    /* box-shadow: 0px 0px 2px gray; */
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}
.eMed_pharma_settings_option_Main{
    height: 6vh;
    width: 95%;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1vw;
}

.eMed_pharma_settings_option_action{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
}

#eMed_pharma_settings_stkTbl {
    width: 100%;
    height: 47vh;
}

.eMed_pharma_usrconfig_container {
    height: 80vh;
    background-color: #F5F8F7;
    margin: 0.625vw;
}

.eMed_pharma_usrconfig_tbl {
    height: 77vh;
}
.eMed_generic_editDiv {
    width: 100%;
    height: 38vh;
    overflow-y: scroll;
}

#eMed_generic_chipDiv {
    width: 98%;
    height: 25vh;
    margin: 0.1vw auto;
    overflow-y: scroll;
}

.eMed_generic_dropdown {
    width: 20vw;
    margin-bottom: 1vw;
}

#eMed_generic_chip {
    border-radius: 0.3vw;
    margin: 0.3vw 0.5vw 0.3vw 0vw;
    height: 2vw;
}
.eMed_sublocation_add {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 33vw;
    height: 50vh;
    background-color: white;
    border-radius: 10px;
}

.eMed_subloc_con{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1vw;
}
.eMed_subloc_conSub{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1vw;
    width: 23vw;
}

.eMed_SubLoc_patBill{
    border: 1px solid #888888;
    border-radius: 0.3vw;
    display: flex;
    flex-direction: row;
    margin-top: 0.5vw;
}

.eMed_SubLoc_VRLine{
    border-right: 1px solid #888888;
    height: 2.3vw;
    margin-right: 1vw;
    display: flex;
    align-self: center;
}

.eMed_stock_edit_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65vw;
    background-color: white;
    border-radius: 10px;
}

.eMed_active_view_textfield {
    padding: 0.625vw;
}

.eMed_stock_edit_radio_card {
    margin: 0.7vw;
    height: 5vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    padding: 0.5vw;
}

.eMed_stock_edit_input_card {
    margin: 0.625vw;
    height: 7vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
}

.eMed_stock_edit_middle_card {
    border-radius: 5px;
    margin: 0.625vw;
    min-height: 17vw;
    max-height: 20vw;
    box-shadow: 0px 0px 2px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.eMed_active_view_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}