#eMed_BM_mainDiv {
    width: 100%;
    height: 84vh;
    background-color: #F5F8F7;
    overflow-y: scroll;
    scroll-behavior: smooth;
    /* padding: 0.5vw; */
}

.eMed_Ovarall_Chart_Wrapper {
    width: 100%;
    height: fit-content;
    margin: 0.5vw auto;
}
.eMed_OP_Chart_Wrapper {
    width: 100%;
    height: fit-content;
    margin: 0.5vw auto;
    margin-top: 1vw;
}


.eMed_BM_Div1 {
    width: 100%;
    height: fit-content;
    margin: 0.5vw auto;
}

.eMed_Individual_DashChart {
    width: 100%;
    /* height: fit-content; */
    height: 18.8vw;
    /* margin-bottom: 0.6vw; */
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background-color: white;
    border-radius: 0.4vw;
    /* box-shadow: 0px 0px 3px gray; */
    column-gap: 0.5vw;
}

.eMed_DashChart_CardView {
    width: 12.35vw;
    /* height: fit-content; */
    height: 9.2vw;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background-color: white;
    border-radius: 0.4vw;
    /* box-shadow: 0px 0px 3px gray; */
}

.eMed_DashChart_CardView_Wrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5vw;
    row-gap: 0.5vw;
}

.eMed_Divider_Div{
    margin-left: 0.5vw;
    margin-bottom: 1vw !important;
}

.eMed_CharhPage_Header_div{
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.5vw;
}

.eMed_CharhPage_Header_filter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1vw;
}

.eMed_CharhPage_filter_elements{
    width : 23vw !important;
    margin-bottom: 1vw;
    padding-left: 2vw;
}

.eMed_CharhPage_filter_elements_compare{
    width : 20vw !important;
    margin-bottom: 1vw;
    padding-left: 2vw;
}

.eMed_CharhPage_filter_elements_select{
    width : 22vw !important;
    margin-bottom: 1.8vw;
    padding-left: 2vw;
}

.eMed_Chart_Dashboard_Filter_main{
    margin-top: 5vw;
    width: 25vw;
}

.eMed_Dashboard_Chart_wrapper{
    height: 80vh;
    overflow-y: scroll;
    width: 100%;
    padding-left: 0.5vw;
}