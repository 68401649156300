.eMed_Doc_Img_Pop_Up{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 35vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}
.eMedhub_highlight_column{
    background-color:#FFE1DE !important;
}

.eMedhub_highlight_column:hover{
    background-color:#FFE1DE !important;
}
