.eMed_common{
    min-width: 0vw !important;
}

#emedhub_Transit_mainBox{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25vw;
    max-height: 40vw;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
    outline: none;
}

.emedhub_transit_img{
    width: 1vw;
    height: 1vw;
    border-radius: 0.3vw;
}
 
.eMed_batch_delete {
    margin-left: 0.5vw;
    display: flex;
    align-self: center;
    cursor: pointer;
    width: 1vw;
    height: 1vw
}