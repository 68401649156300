.eMed_IPappoint_Stepper_box{
    height: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 3vw;
}

#eMed_back_btn{
    color: black;
    text-transform: capitalize;
}

.eMed_Ipappoint_container {
    margin-top: 0.5vw;
    background-color: #F5F8F7;
}

.eMed_IPappoint_content {
    height: 80vh;
    margin-top: 0.5vw;
}

.eMed_IPappoint_right {
    height: 72vh;
    background-color: white;
    width: 30vw;
    margin-right: 1vw;
}

.eMed_IPappoint_left {
    width: 61vw;
    height: 72vh;
    margin-left: 1vw;
    background-color: white;
    padding-top: 0 !important;
    overflow-y: scroll;
}
.eMed_Ipappoint_btn_div{
    display: flex;
    justify-content: center;
}

#eMed_invd_Button{
    text-transform: capitalize;
    margin-left: 1vw;
}

.eMed_IPappoint_title {
    height: 6vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    background-color: #FAF9F9;
    font-weight: 600 !important;
}
.eMed_IPappoint_Grid_row{
    padding-left: 1.8vw;
}

.eMed_IPappoint_Medico_div{
    display: flex;
    flex-direction: row;
}

.eMed_Ipadmission_Avatar_box{
    display: flex;
    justify-content: center;
    margin-top: 1vw;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1vw;
}
.eMed_Ipadmission_Avatar_img{
    height: 10vw;
    width: 10vw;
    border-radius: 5vw;
}

.eMed_IPappoint_Patient_Details{
    width:"100%";
    display: flex;
    justify-content: flex-start;
    margin: 2vw;
}

.eMed_TextEditor_Accord{
    min-height: 14vh;
}

.eMed_IP_BarCode_Box{
    /* width:"100%"; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1vw;
    margin-left: 4vw;
    margin-right: 4vw;
    border:solid grey 1px;
    border-radius: 0.3vw;
    padding-bottom: 1vw;
}
.eMed_IP_barcode_title{
    height: 4vh;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: black;
    font-size: 0.8vw !important;
    border-top-right-radius: 0.3vw;
    border-top-left-radius: 0.3vw;
}

.eMed_IP_barcode_img{
    display: flex;
    justify-content: center;
    padding-top: 1vw;
}

.eMed_IP_BarCode_PrintBox{
    display: flex;
    justify-content: center;
    padding-top: 1vw;
    width: 100%;
}

.eMed_MobileNum_Main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 35vw;
    background-color: white;
    border-radius: 5px;
    outline: none;
}

.eMed_MobileNum_img{
    min-width: 55vw;
    min-height: 30vw;
}

.eMed_MobileNum_Header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}
.eMed_MobileNum_wrapper{
    justify-content: center;
    height: 31vw;
    width: 29vw;
    overflow-y: scroll;
}

.eMed_Patient_Num_Search_pop{
    display: flex;
    width: 28vw;
    justify-content: space-between;
    padding: 1vw;
    align-items: center;
}

.eMed_Reservation_Modal_main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 60vh;
    background-color: white;
    border-radius: 5px;
}

.eMed_Reservation_Modal_Header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}
.eMed_Reserve_Table_Wrapper{
    height: 58vh;
}

.eMed_reserve_dropdown{
    width: 15vw;
    margin: 1vw 1vw !important;
}