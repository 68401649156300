.eMed_Purcahse_Table_Container{
    height: 70vh;
}

.eMed_Purchase_Table_Wrapper {
    width: 92vw;
    height: 70vh;
    margin-left: 1vw;
    background-color: white;
}

.eMed_Purchase_Table_top{
    height: 9vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1.1vw;
    padding-left: 2vw;
    align-items: center;
}

.eMed_AddVendor_Grid_row{
    padding: 0.5vw;
    height: 9vh;
}
.eMed_Add_vendor_page_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 8vh;
    align-items: center;
    padding-right: 3vw;
}
.eMed_Add_vendor_page_top2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 20vw;
}

#eMed_pharmadrctpo_Container {
    width: 100%;
    margin-top: 1.5vw;
    padding: 0 0.5vw;
}

#eMed_pharmartnDrctpo_Container {
    width: 100%;
}

#eMed_pharmadrctpo_mainDiv {
    height: 80vh;
    background-color: #f5f8f7;
}

#eMed_pharmartnDrctpo_mainDiv {
    height: 75vh;
}

#eMed_pharmadrctpo_paper1 {
    height: 15vh;
    border-radius: 0.3vw;
    background-color: #FFFFFF;
    padding: 0.5vw;
    margin-bottom: 1.5vw;
}

#eMed_pharmadrctpo_viewSkDiv {
    height: 13vh;
    width: 93.5vw;
    background-color: #FFFFFF;
    padding: 0.5vw;
    margin-bottom: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

#eMed_pharmartnDrctpo_paper1 {
    height: 15vh;
    border-radius: 0.3vw;
    background-color: #FFFFFF;
    padding: 0.4vw;
    margin-bottom: 1vw;
}

.eMed_pharmadrctpo_subDiv {
    display: flex;
    justify-content: flex-start;
    height: 7vh;
}

.eMed_pharmadrctpo_txt {
    margin: 0.5vw;
}

#eMed_pharmadrctpo_table {
    height: 57vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
}

#eMed_pharmartnDrctpo_table {
    height: 54vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
}

.eMed_pharmadrctpo_tblhead {
    display: flex;
    flex: 1;
}

#eMed_pharmadrctpo_tblheadTxt {
    background-color: #faf9f9;
    color: #616161;
    font-weight: 600;
    font-size: 0.8vw;
    padding: 0.5vw 0.5vw !important;
}

#eMed_pharmadrctpo_tblbodyTxt {
    font-size: 0.9vw;
    font-weight: 600;
}

#eMed_pharmadrctpo_tblTxt {
    color: #66686A;
    font-size: 0.75vw;
}

#eMed_pharmadrctpo_Txtclr {
    color: #66686A;
    padding: 0.5vw 0.5vw !important;
}

#eMed_pharmartnDrctpo_rhtDiv {
    height: 72vh;
    border-top-left-radius: 0vw;
    border-top-right-radius: 0.3vw;
    border-bottom-left-radius: 0vw;
    border-bottom-right-radius: 0vw;
}

#eMed_pharmadrctpo_rhtDiv {
    height: 75vh;
    border-top-left-radius: 0vw;
    border-top-right-radius: 0.3vw;
    border-bottom-left-radius: 0vw;
    border-bottom-right-radius: 0vw;
}

.eMed_pharmadrctpo_tileBlk {
    background-color: #E4F2F0;
    border-radius: 0.3vw;
    padding: 0.3vw;
    height: 4vw;
    margin-top: 0.6vw;
}

#eMed_pharmadrctpo_tabDiv {
    background-color: #E4F2F0;
    height: 5vh;
    margin-bottom: 0.2vw;
}

#eMed_pharmadrctpo_stsTxt{
    font-size: 0.9vw;
    color: #FF9900;
}

#eMed_pharmadrctpo_txtbox {
    margin: 0.7vw;
}

#eMed_pharmadrctpo_rhtSubDiv {
    height: 59vh;
    overflow: scroll;
}

.eMed_pharmadrctpo_rhtHead {
    background-color: #faf9f9;
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eMed_pharmadrctpo_rhtHead_txt {
    font-size: 0.9vw;
    font-weight: 600;
    margin-left: 1.5vw;
}

.eMed_pharmadrctpo_rhtCntBox {
    background-color: #FFFFFF;
    padding: 1vw;
}

#eMed_pharmadrctpo_rhtCntBox1 {
    max-height: 53vh;
}

#eMed_pharmadrctpo_rhtCntBox2 {
    max-height: 95vh;
}

.eMed_pharmadrctpo_rhtCnSubDiv {
    display: flex;
    justify-content: space-between;
}

.eMed_pharmadrctpo_rhtCnt_TleBlk {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#eMed_pharmadrctpo_rhtBtnCon {
    background-color: #faf9f9;
    height: 4.8vh;
    padding: 0.625vw;
}

#eMed_pharmadrctpo_btcBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    max-height: 30vw;
    padding: 3vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
.eMed_Add_vendor_form_box{
    height: 74vh;
    overflow: scroll;
}

.eMEd_Add_Vendor_Form_Headtext{
    font-weight: 600;
    /* padding-left: 1vw; */
}

.eMed_add_vendor_dropdown_inputs{
    width: 95%;
    height: 2.5vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
}

.eMed_PharmacfrmOrd_mainDiv{
    width: 100%;
}
#eMed_PharmacfrmOrd_subDiv1{
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    max-height: 16.98vh;
    padding:0.625vw;
}

.eMed_PharmaVendor_mainBox {
    width: 100%;
    height: 85vh;
    background-Color: #f5f8f7;
    padding: 0.625vw;
}

.eMed_PharmaVendor_paper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 14vw;
}

#eMed_PharmaVendor_headTxt {
    font-size: 0.75vw;
    color: #66686A;
}

#eMed_PharmaVendor_Txt {
    font-weight: 600;
}

#eMed_PharmaVendor_Table {
    height: 44vh;
    background-color: #FFFFFF;
}

#eMed_PharmaVendor_popupBox {
    width: 55vw;
    max-height: 60vw;
    padding: 0.5vw;
    background-color: #FFFFFF;
}

#eMed_PharmaVendor_popHeadTxt {
    font-weight: 600;
}

#eMed_PharmaVendor_popNorTxt {
    color: #66686A;
}

.eMed_OrdTxtField input[type="number"]::-webkit-inner-spin-button,
.eMed_OrdTxtField input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

#eMed_pharmaReturnPO_table {
    height: 50vh;
}

.eMed_date_filter_vendor{
    width: 15vw;
    margin-top: 1.5vw;
    height: 5vh;
    position: relative;
    left: 8vw;
    margin-bottom:1.5vw;
}

.eMed_PO_Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    height: 40vw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
}