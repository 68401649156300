.total {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1;
  background-color: white;
}

.right {
  flex: 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: #d9fbff;
  flex-direction: column;
}
.eMed_login_checkbox {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-top: 7%;
}

.butt {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 7%;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
#label_link {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
#label_link:hover{
  color: blue;
  text-decoration: underline;
}
.eMed_textFiled {
  max-width: 90%;
}
.eMed_passwordTextbox {
  margin-top: 7%;
}
.eMed_visibleIcon {
  cursor: pointer;
}

.eMed_Login_HeadCon {
  margin: 1vw 1vw 0vw 1vw;
  display: flex;
  align-content: center;
}