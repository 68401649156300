.eMed_MainComponentDiv{
    height: 78vh;
}
.eMed_SubComponentDiv{
    height: 72vh;
}
.eMed_tabWrapper{
    display:flex;
    flex-direction: row;
    width:100%;
    border-bottom: 1px solid lightgray;
}
.css-eopcd7-MuiButtonBase-root-MuiTab-root{
    max-width: 23vw !important;
    min-width: 5.5vw !important;
}

.eMed_NoAccess_Text{
    font-weight: 600 !important;
    padding-left: 40vw !important;
}