:root {
    --bgColor: #F5F8F7;
    --headBorder: #cbbfbf;
    --white: #ffff;
    --headboxShadow: #e3e0e0;
    --error: #F8E8E7;
}

.eMed_IPDocAptTbl_Con {
    height: 70dvh;
    width: 100%;
    background-color: white;
    cursor: pointer;
}

.eMed_IPDoc_AptHead {
    border-bottom: 1px solid rgb(203, 191, 191);
    box-shadow: 0px 2px #e3e0e0;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    height: 6dvh;
}

.eMed_IPDocFlt_Con {
    width: 100%;
    height: fit-content;
    background-color: white;
    margin: 3px auto;
    padding: 0.625dvw 0dvw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eMed_IPDocFlt_txt {
    font-size: 1dvw;
    font-weight: 700;
    padding-left: 0.5dvw;
    border-bottom: 1px solid rgb(203, 191, 191);
}

.eMed_IPDocFlt_subCon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.eMed_IPDoc_autoComplete {
    width: 14dvw;
    margin: 0dvw 0.3dvw !important;
}

.eMed_Header_IP_Status_btn {
    margin-right: 1dvw !important;
    text-transform: capitalize !important;
    color: black !important;
    border-radius: 2px !important;
    box-shadow: 0px 0px 5px gray !important;
    padding-left: 0.5dvw !important;
    padding-right: 0.5dvw !important;
    width: 6dvw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}