.eMed_Main_Container{
    height: 79dvh;
    background-color: #f5f8f7;
}

.eMed_Header_Container{
    display: flex;
    height: 15dvh;
}

.eMed_Header_Rgt_Content{
    flex: 0.9;
    display: flex;
    align-items: center;
}

.eMed_Header_Lft_Content{
    flex: 0.1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.eMed_Table_Content{
    height: 65dvh;
    padding: 0.5vw;
}

.eMed_Header_Btn{
    height: 2vw !important;
    width: 2vw !important;
    margin-right: 0.5vw !important;
}