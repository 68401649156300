.eMed_revenueCard_div {
    width: 12.35vw;
    height: 9.2vw;
    padding: 0.5vw;
}

.eMed_revenueCard_imgDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5vw;
}

.emedhub_revenueCard_img {
    width: 2.4vw;
    height: 2.4vw;
    margin-right: 0.3vw;
}

.eMed_revenueCard_countDiv {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0.6vw;
}

#eMed_revenueCard_headTxt {
    font-size: 0.85vw;
    font-weight: 600;
}

#eMed_revenueCard_Txt {
    font-size: 0.85vw;
    color: #616161;
}

#eMed_revenueCard_countTlt {
    font-size: 0.8vw;
    color: #616161;
    display: flex;
    align-items: center;
}

#eMed_CountCard_CountLabel {
    font-size: 0.8vw;
    display: flex;
    align-items: center;
}

#eMed_revenueCard_comparTlt {
    font-size: 0.7vw;
    color: #616161;
    display: flex;
    align-items: center;
}

#eMed_revenueCard_count {
    font-size: 0.8vw;
    color: #616161;
    font-weight: 600;
    padding-left: 0.3vw;
}

#eMed_CountCard_Amount {
    font-size: 0.8vw;
    color: #616161;
    font-weight: 600;
}

#eMed_revenueCard_profitSts {
    font-size: 0.6vw;
    color: #616161;
}

#eMed_revenueCard_amount {
    font-size: 1.23vw;
    font-weight: 600;
}

#eMed_CountCard_CountValue {
    font-size: 1vw;
    font-weight: 600;
    padding-left: 0.3vw;
}

.eMed_revenueCard_profit {
    width: 0.8vw;
    height: 0.8vw;
    margin-right: 0.2vw;
}

.eMed_revenueCard_profit_expand {
    width: 1.2vw;
    height: 1.2vw;
    margin-right: 0.6vw;
}

.eMed_revenueTbl_titleDiv {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0vw 0.5vw;
    border-bottom: 1px solid #ddd9d9;
}

.emedhub_expand_img {
    width: 1.3vw;
    height: 1.3vw;
}

.eMed_barChart_chartTltDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 10%;
}

.eMed_barChart_chartTltDiv_expand {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20%;
    padding-right: 2vw;
    padding-top: 1vw;
}

.eMed_barChart_content{
    width: 100%;
    height: 90%;
}

.eMed_barChart_imgDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5vw;
}


#eMed_barChart_Txt {
    font-size: 0.8vw;
    color: #616161;
}

#eMed_barChart_Txt_expand {
    font-size: 1vw;
    color: #616161;
}

.eMed_doughnut_chartDiv {
    width: 100%;
    height: 85%;
    padding: 0.6vw;
}

.eMed_expandPop_Main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75vw;
    height: 35vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_expandPop_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31vw;
    width: 100%;
    overflow-y: scroll;
    padding: 0.625vw;
}

.eMed_chart_wrapper{
    width: 100%;
    height: 18.8vw;
}

#eMed_chart_headTxt {
    font-size: 0.9vw;
    font-weight: 600;
}

.eMed_noDataDiv {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-ticks{
    font-size: 0.8vw;
}