:root{
    --bgColor: #F5F8F7;
    --headBorder: #cbbfbf;
    --white: #ffff;
    --headboxShadow: #e3e0e0;
    --error: #F8E8E7;
}

.eMed_OT_Main_Container{
    width: 100%;
    height: 100%;
    margin-top: -0vw;
}

.eMed_OT_page_header{
    border-bottom: 1px solid var(--headBorder);
    box-shadow: 0px 2px var(--headboxShadow);
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    justify-content: space-between;
    align-items: center;
    height: 8vh;
}

.eMed_Price_Card_Icon {
    display: flex;
    flex-direction: row;
    width: 25vw;
    align-items: center;
}

.eMed_IP_AptDateFlt {
    width: 13vw;
    position: relative;
    top: 0.36vw;
}

.eMed_OT_Apt_Cards {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.eMed_OT_APtTbl_PayPending {
    margin-left: 0.3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_OT_AptTbl_PayIcon {
    height: 0.9vw;
    width: 1vw;
    margin-right: 0.3vw
}

#eMed_OT_AptTbl_PayText {
    font-size: 0.9vw;
    font-weight: 600;
}

.rendercell{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}