.eMed_Chart_Layout {
    height: 73vh;
    margin: 0.2vw;
    box-shadow: 0px 0px 2px gray;
}

.eMed_charts_sub_title {
    /* height: 6vh; */
    display: flex;
    align-items: center;
    padding-left: 1vw;
    font-weight: 600 !important;
    color: #616161;
    margin-top: 0.8vw;
}

.eMed_Ante_Chart_Tabs {
    background-color: white;
    margin: 0.5vw;
    box-shadow: 0px 0px 2px gray;
    border-radius: 0.1vw;
}