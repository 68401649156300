.doc_header_card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    height: 3.5vw;
    padding: 0.2vw;
    margin-top: 0.5vw;
}

.eMed_Chip_Border {
    height: 2vw !important;
    background-color: white !important;
    font-weight: 600 !important;
    border: 1px solid #04B7B1 !important;
    border-radius: 6px !important;
}
.eMed_Chip_redBorder {
    height: 2vw !important;
    background-color: white !important;
    font-weight: 600 !important;
    border: 1px solid red !important;
    border-radius: 6px !important;
}

.eMed_Chip_OnClick{
    height: 2vw !important;
    background-color: #04B7B1 !important;
    font-weight: 600 !important;
    border: none !important;
    color: white !important;
    border-radius: 6px !important;
}
.eMed_Chip_redOnClick{
    height: 2vw !important;
    background-color: #04B7B1 !important;
    font-weight: 600 !important;
    border: 2px solid red !important;
    color: white !important;
    border-radius: 6px !important;
}

.eMed_BoxShadow {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    height: 2.2vw;
    width: 9vw;
    margin-top: 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center
}

.eMedhub_Shadow_Properties{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.doc_header_card_New {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    height: 3.5vw;
    margin-top: 0.5vw;
    padding-left: 0.2vw;
}