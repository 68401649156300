.eMed_pharma_bill_tbl_footer {
    display: flex;
    justify-content: space-between;
    height: 2vw;
    width: 30vw;
    margin-right: 1vw;
    margin-top: 0.25vw;
}
.eMed_recPayment_Div {
    height: 67vh;
    margin-top: 0.65vw;
}
.emedhub_recPayment_RightContentBox {
    background-color: var(--white);
    padding: 0.45vw;
    margin-left: 0.4vw;
}