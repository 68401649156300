.eMed_TestConfig_MainContainer {
  width: 100%;
  height: 80vh;
  background-color: #f5f8f7;
  margin-top: 1vw;
}

.eMed_TestConfig_Con {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.eMed_TestConfig_leftSide {
  width: 84.6vw;
  height: 74vh;
  background-color: white;
  margin: 1vw;
  border-radius: 0.3vw;
}

.eMed_TestConfig_RightSide {
  width: 44.9vw;
  height: 74vh;
  background-color: white;
  margin: 1vw;
  border-radius: 0.3vw;
}

.eMed_TestConfig_RightHead {
  background-color: #faf9f9;
  height: 7.3vh;
  border-radius: 0.3vw 0.3vw 0vw 0vw;
  display: flex;
  align-items: center;
}

.eMed_Testconfig_RightHead_text {
  font-weight: bold;
  margin-left: 1.5vw;
  font-size: 1vw;
  color: #616161;
}

.eMed_TestConfig_RightContentBox {
  height: 59.1vh;
  overflow: scroll;
  position: relative;
}

.eMed_TestConfig_RightBtnCon {
  background-color: #faf9f9;
  height: 7.3vh;
  border-radius: 0vw 0vw 0.3vw 0.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eMed_TestConfig_LeftPageination {
  background-color: #faf9f9;
  height: 7.3vh;
  border-radius: 0vw 0vw 0.3vw 0.3vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.eMed_TestConfig_RightTestName {
  margin: 1vw;
  margin-bottom: 0;
  /* width: 28vw; */
  display: flex;
  flex-direction: column;
}

.eMed_TestConfig_RightMethod {
  margin: 1vw;
  width: 29vw;
  margin-bottom: 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eMed_TestConfig_RightHead2 {
  background-color: #faf9f9;
  height: 7.3vh;
  display: flex;
  margin-top: 1vw;
  align-items: center;
}

.eMed_ConfigTable_Paper {
  height: 61vh;
}

#eMed_config_TableRow {
  background-color: #faf9f9;
  font-weight: bold;
  height: 1vh;
  font-size: 0.9vw;
  color: #616161;
}

#eMed_config_TableRow1 {
  background-color: #faf9f9;
  font-weight: bold;
  height: 1vh;
  border-radius: 0.3vw 0 0 0;
  font-size: 0.9vw;
  color: #616161;
}

.eMed_TestConfig_Right_SideTable {
  margin: 1vw;
}

.eMed_ConfigRightTable_Paper {
  height: 32vh;
  overflow: scroll;
  border-style: 3px solid #c9c1c1;
  border-radius: 0.3vw;
}

.eMed_TestConfig_ImpressionCon {
  height: auto;
  margin: 1vw;
  border: 1px solid #e3e2e2;
  border-radius: 0.3vw;
}

.eMed_TestConfig_ImpressionHeadCon {
  background-color: #faf9f9;
  border-radius: 0.3vw 0.3vw 0vw 0vw;
  margin-top: 0vw;
  height: 5vh;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e2e2;
}

.eMed_TestConfig_ImpressionText {
  font-weight: 600;
  margin-left: 1vw;
  color: #616161;
}

.eMed_TestConfig_ImpressionAdd {
  margin: 1vw;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.eMed_TestConfig_Addpara {
  display: flex;
  justify-content: center;
}

.eMed_TestConfig_RightMethodAddBtn {
  margin: 1vw;
  width: 29vw;
  margin-bottom: 0vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.eMed_TestConfig_NoRecords {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23vh;
}

.eMed_TestConfig_ParaHeadCon {
  background-color: #faf9f9;
  border-radius: 0.3vw 0.3vw 0vw 0vw;
  margin-top: 0vw;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e2e2;
}

.eMed_TestConfig_CrossIcon {
  display: flex;
  align-self: flex-end;
  margin-right: -0.5vw;
  z-index: 1300;
  margin-bottom: -0.7vw;
  height: 1vw;
  cursor: pointer;
}

.eMed_TestConfig_HeaderCross {
  margin-right: 1vw;
  height: 1.3vw;
  cursor: pointer;
}

.eMed_TestConfig_EditIcon {
  width: 1.3vw;
  height: 1.3vw;
  margin-right: 0.5vw;
  cursor: pointer;
}

.eMed_TestConfig_DeleteIcon {
  width: 1.3vw;
  height: 1.3vw;
}

.eMed_PatientDemo_Name {
  font-weight: 600;
  font-size: 1vw;
}

.eMed_PatientDemo_Address1 {
  font-size: 0.6vw;
  color: #616161;
  margin-top: -1vw;
}

.eMed_PatientDemo_Address2 {
  font-size: 0.6vw;
  color: #616161;
  margin-top: -0.7vw;
}

.eMed_Patient_Country {
  width: 14vw;
  height: 2.6vw;
  border-color: #bbb9b9;
  color: #888888;
  border-radius: 0.3vw;
}

#eMed_Btn_Text {
  text-transform: capitalize;
}

.eMed_warning {
  display: flex;
  justify-content: space-between;
}

.eMed_imp_txt {
  display: flex;
  flex-direction: column;
}

.eMed_Testconfig_AddIcon {
  width: 1.5vw;
  height: 1.5vw;
}

.eMed_Search_Box_ {
  display: flex;
  justify-content: end;
  padding-top: 0.7vw;
}

.eMed_Main_Tbl_card {
  border: 1px solid lightgray;
  margin: 0.5vw;
  width: 90vw !important;
}

.eMed_Main_Tbl_head {
  font-size: 0.9vw !important;
  font-weight: 600 !important;
  background-color: #f5f8f7 !important;
  color: #616161 !important;
}

.eMed_Lab_act_btn {
  min-width: 0vw !important;
}

.eMed_Lab_Rst_Tbl_Card{
  background-color: white;
  height: 78vh;
  width: 92.6vw;
  border: 1px solid lightgray;
}

.eMed_Lab_Rst_Tbl_Container{
  height: 77.5vh;
  border: 1px solid lightgray;
  background-color: white;
}

.eMed_Tbl_rst_Cell_One{
  border-right: 1px solid lightgray;
  width: 10vw;
}

.eMed_Tbl_rst_Cell_Two{
  border-right: 1px solid lightgray;
  width: 18vw;
}

.eMed_Tbl_rst_Cell_Three{
  border-right: 1px solid lightgray;
  width: 12vw;
}

.eMed_Tbl_rst_Cell_four{
  border-right: 1px solid lightgray;
  width: 15vw;
}

.eMed_lab_rest_New_Popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45vw;
    max-height: 40vw;
    background-color: white;
    /* border-radius: 8px; */
    overflow: scroll;
}

.react-image-crop__handle.react-image-crop__handle--n,
.react-image-crop__handle.react-image-crop__handle--e,
.react-image-crop__handle.react-image-crop__handle--s,
.react-image-crop__handle.react-image-crop__handle--w {
  display: none !important;
}