/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

} */

.total {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1;
  background-color:white;
}

.right {
  flex: 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.login_input input{
  display: flex;
  border-radius: 5px;
   width:300px;
   background-color:#d9fbff;
   flex-direction: column;  
  
} 
.login_checkbox{
  display: flex;
 justify-content: space-between;
}

.btn{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 7%;
}
.logo_img{
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color:white;

}
.eMed_dilboxAnimations {
  width: 5vw;
  height: 10vh;
}