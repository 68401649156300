.eMed_pd_maintable {
    height: 71vh;
    margin-top: 1vw;
    margin-left: 1vw;
    background-color: white;
}

.eMed_Table_top_btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 1vw;
    margin-top: 0.5vw;
}

.eMed_Emp_billing_left{
    /* width: 60%; */
    height: 63vh;
    margin-left: 1vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    background-color: white;
}

.eMed_Emp_billing_right{
    /* width: 40%; */
    height: 63vh;
    margin-left: 1vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    background-color: white;
}

.eMed_Emp_Billing_Table{
    /* width: 92vw !important; */
    height: 63vh !important;
    background-color: white;
    box-shadow: 0px 0px 1px light;
    border: 1px solid lightgray;
    overflow: scroll;
}
.eMed_Return_Billing_Table{
    /* width: 92vw !important; */
    height: 55vh !important;
    background-color: white;
    box-shadow: 0px 0px 1px light;
    border: 1px solid lightgray;
    overflow: scroll;
}

.eMed_emp_billing_Popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 30vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_emp_billing_Popup_Top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_EmpBill_SearchTextBox{
    display: flex;
    align-items: flex-end;
    width: 15vw;
    padding-bottom: 1vw;
}

.eMed_emp_bill_split_tableBody{
    max-height: 15vw !important;
}

.eMed_Corp_Card_Con{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 7vw;
}

.eMed_Corp_Card_Wrap{
    width: 40%;
    display: flex;
    justify-content: space-between;
}