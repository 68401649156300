.emed_multibill_container {
    height: 100%;
    background-color: #F5F8F7;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    height: 80dvh;
}

.emed_multibill_header {
    height: 10dvh;
    display: flex;
    flex-direction: row;
}

.emed_multibill_content {
    height: 51dvh;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    overflow-y: auto;
}

.emed_multibill_footer {
    height: 17dvh;
    display: flex;
    flex-direction: row;
}

.emed_multilab_tblbody {
    background-color: white;
    display: flex;
    flex: 1;
    width: 100%;
}