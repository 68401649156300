.eMed_subLocCrdt_Box {
    width: 100%;
    height: 60vh;
}

.eMed_subLocCrdt_headBox {
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.625vw;
}

#eMed_subLocCrdt_viewTbl {
    width: 100%;
    height: 45vh;
    margin-bottom: 0.625vw;
}

.eMed_Sub_Loc_Rpt_Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vw;
    height: 30vw;
    background-color: white;
    overflow: scroll;
}