.eMed_DocNotes_Left_Main{
    flex:0.65;
    height: 64dvh;
    margin: 0.5dvw 0.5dvw 0.5dvw 0.8dvw;
    overflow-y: scroll;
}

.eMed_DocNotes_Right_Main{
    flex: 0.35;
    margin: 0.5dvw;
    height: 64dvh;
    width:32dvw
}

.eMed_DocNotes_DateTime{
    background-color: 'red';
    height: 5vw;
    margin-top: 1vw;
    margin-left: 0.5vw;
    width: 16vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.emed_Duration_head{
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1vw;
    background-color: #FAF9F9;
    font-weight: 600 !important;
    padding-right: 1vw;
}

.eMed_DocNotes_Duration_Warpper{
    display: flex;
    flex-wrap: wrap;
    padding: 0.5vw;
    padding-left: 2vw;
}
.eMed_DocNotes_Duration_Counts{
    margin: 0.4vw;
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.2vw;
    cursor: pointer;
    border: 1px solid ;
}

.eMed_DocNotes_FreqChips_Wrapp{
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    display: flex;
    flex-wrap: wrap;

}

.eMed_DocNotes_FreqChip{
    margin: 0.2vw !important;
    border-radius: 0.3vw !important;
    height: 4.5vh !important;
    font-size: 0.9vw !important;
}

.eMed_DocNotes_TextFields{
    width: 28vw !important;
    margin-left: 1.5vw !important;
    margin-top: 0.5vw !important;
    margin-bottom: 0.5vw !important;
}

.eMed_DocNotes_icd_TxtBox{
    width: 28vw;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 2vw;
    margin-top: 1.5vw;
}

.eMed_DocNotes_ProvDiag_TextField{
    width: 52vw !important;
    margin-left: 1.5vw !important;
    margin-top: 0.5vw !important;
    margin-bottom: 0.5vw !important;
}

.eMed_DocNotes_nodata {
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eMed_DocNotes_datePic_Textfield {
    margin-top: 1.5vw !important;
    margin-left: 0.5vw !important;
    margin-right: 0.5vw !important;
    width: 15vw !important;
}

.eMed_DocNotes_Txt {
    font-weight: 600;
}

.eMed_DocNotes_DateTxt {
    font-size: 0.85dvw;
}

.eMed_WrtNotes_Cont {
    height: 100%;
    padding: 0px 12px;
}

.eMed_WrtNotes_docDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.eMed_WrtNotes_lblCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eMed_WrtNotes_docImg {
    width: 3dvw;
    height: 3dvw;
    border-radius: 50%;
}

.eMed_IPDoc_fltTxtCont {
    width: 20dvw;
    margin: 0.5dvw;
}

.eMed_IPDoc_FilterBox {
    width: 28dvw;
    margin-top: 5dvw;
}

.eMed_IPDoc_fil_content {
    width: 100%;
    height: 74dvh;
    margin: 0.5dvw;
}

.eMed_IPDoc_Date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eMed_IPDoc_fil_text {
    width: 26dvw;
}

.eMed_IPDoc_BtnText {
    width: 6dvw;
    text-transform: capitalize !important;
}

.eMed_IPDoc_printBtn {
    height: 2dvw;
    width: 4.8dvw;
    text-transform: capitalize;
}