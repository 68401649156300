.eMed_voice_to_Text{
    height: 75px;
    width: 75px;
    background: linear-gradient(
        #82fbfb,
        #b8d2f7
    );
    position:relative;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    border-radius: 50%;
    display:grid;
    place-items: center;
    top: -40px;
}
.eMed_voice_to_Text::before,
.eMed_voice_to_Text::after{
        content: "";
        position: absolute;
        height: 80%;
        width:80%;
        background-color: #82fbd7;
        border-radius:50%;
        z-index: -1;
        opacity:0.7;
         
        
}
.eMed_voice_to_Text::before{
    animation: eMed_voice_to_TextAnimate 2s ease-out infinite;
}
.eMed_voice_to_Text::after{
    animation : eMed_voice_to_TextAnimate 4s  ease-out infinite;
}
@keyframes eMed_voice_to_TextAnimate{
    100%{
        transform: scale(2.5);
        opacity: 0;
    }
}
 