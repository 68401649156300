.total {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1;
  background-color: white;
}

.eMed_Forget_Con {
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: #d9fbff;
  flex-direction: column;
}
Button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 7%;
  background-color: rgb(4, 183, 177);
  color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
.link {
  color: white;
  text-decoration: none;
}

#eMed_Forget_Btn {
  margin: 1vw;
  width: 10vw;
  text-transform: capitalize;
}

#eMed_Forget_HelpText {
  margin: 1vw;
  font-size: 0.9vw;
  color: #616161;
}

#eMed_Verify_HelpText {
  margin: 0vw 1vw 1vw 1vw;
  font-size: 0.9vw;
  color: #616161;
}

#eMed_Forget_HeaderText {
  font-size: 1.3vw;
  font-weight: bold;
  margin-left: 1vw;
}

.eMed_Forget_HeadCon {
  margin: 1vw 1vw 0vw 1vw;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.eMed_Forget_Box {
  width: 25vw;
  height: 27vw;
  display: flex;
  flex-direction: column;
  border-radius: 0.3vw;
  box-shadow: 0vw 0.3vw 0.9vw 0vw #d3f5f3;
}


#otp_num1{
  padding: 8.5px 9px !important
}
#otp_num2{
  padding: 8.5px 9px !important
}
#otp_num3{
  padding: 8.5px 9px !important
}
#otp_num4{
  padding: 8.5px 9px !important
}
#otp_num5{
  padding: 8.5px 9px !important
}
#otp_num6{
  padding: 8.5px 9px !important
}

.eMed_Forget_ToastCon {
  width: 25vw;
  height: 4vw;
}

.eMed_Forget_Toast {
  min-width: 20vw;
  height: 2vw;
  margin: 0.5vw;
  border-radius: 0.25vw;
  display: flex;
  align-items: center;
  flex-direction: row;
}

#eMed_Forget_ToastText {
  color: white;
  margin-left: 1vw;
}

.eMed_Resent_CodeCon {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
}

#eMed_Resent_CodeText {
  font-size: 0.9vw;
  color: #616161;
  text-decoration: underline;
  cursor: pointer;
}

#eMed_Resent_CodeText:hover {
  color: blue;
  text-decoration: underline;
}