.emedhub_Purchasecontainer{
    width: 100%;
    height: 80vh;
}
.emedhub_purchase_contentContainer{
    width: 100%;
    /* margin: 1vw 0; */
}
.emedhub__purchase_page{
    width: 100%;
    margin-top: 0.625vw;
}
.emedhub_vendor_btnDiv{
    padding: 0.2vw;
    border-right: 2px solid #66686A;
}
.emedhub_vendor_printBtn{
    width:2.3vw;
    height:2vw;
    border-radius:0.3vw;
    background-color:#ffff;
    border:1px solid #66686A;
    padding:0.5vw;
}
#emedhub_ventor_Table{
    height: 68vh;
    margin: 1vw;
}
#emedhub_deliveryChallen_Table{
    height: 70vh;
    margin-left: 1vw;
    margin-right: 1vw;
}
#emedhub_vendorDetails_mainBox{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    max-height: 50vw;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
#emedhub_vendorDetails_header{
    display: flex;
    justify-content: space-between;
    height: 3vw;
    padding: 0.9vw;
    background-color: #FAF9F9;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    outline: none;
}
.emedhub_vendor_textBox{
    width:17.85vw;
}
.emedhub_vendor_dropDown{
    width: 17.85vw;
    height: 2.7vw;
    font-size: 1vw; 
    color: #66686A;
    padding-left: 0.5vw;
    border-color: #CDD1D5;
    border-radius: 0.2vw;
    outline: none;
}
.emedhub_vendor_subDiv{
    display: flex;
    justify-content: space-evenly;
    margin: 1.8vw;
}
#emedhub_vendorDetails_footer{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top: 3vw;
    height: 4vw;
    padding: 1.5vw;
    background-color: #FAF9F9;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
}
.emedhub_vendorDetails_footer_subdiv{
    display:flex;
    align-items:center;
    margin:-1vw;
}
.emedhub_vendorDetails_bulletin {
    width: 0.25vw;
    height: 0.25vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}
.emedhub_vendorDetails_footer_Text1{
    font-size: 0.7vw;
    font-weight: 600; 
    color: rgba(0, 0, 0, 0.5); 
    border-right: 0.15vw solid rgba(0, 0, 0, 0.5);
    padding: 0 0.3vw;
}
.emedhub_vendorDetails_footer_Text2{
    font-size: 0.7vw;
    font-weight: 400; 
    color: rgba(0, 0, 0, 0.5);
    padding: 0 0.3vw;
}
.emedhub_vendor_imgBtn{
    width: 2vw;
    height:2vw;
    padding: 0.3vw;
    display:flex;
    align-items: center;
    background-color:#04B7B1;
    margin: 0.3vw;
    border-radius: 0.3vw;
    cursor: pointer;
}
.emedhub_vendor_img1{
    width: 2.2vw;
    height: 2.3vw;
    border-radius: 0.3vw;
}
.emedhub_vendor_img2{
    width: 2vw;
    height: 2vw;
    border-radius: 0.3vw;
}
#emedhub_vendorDetails_error{
    z-index: 2;
    font-size: 0.78vw;
    position: absolute;
    margin-top: 0.4vw;
    margin-left: 0.7vw;
    color: #ff0000;
}
#emedhub_directpo_mainDiv {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: space-evenly;
    background-color: #f5f8f7;
}

#emedhub_directpo_leftDiv {
    width: 84.6vw;
    margin: 1vw;
}

.emedhub_directPo_imgBtn {
    width: 1.8vw;
    height: 1.8vw;
    margin: 0vw 1vw 0vw 0vw;
}
.emedhub_directPo_img{
    width: 2vw;
    height: 2vw;
    border-radius: 0.3vw;
}
#emedhub_directpo_rightDiv {
    width: 44.9vw;
    margin: 1vw;
    border-top-left-radius: 0vw;
    border-top-right-radius: 0.3vw;
    border-bottom-left-radius: 0vw;
    border-bottom-right-radius: 0vw;
}
#emedhub_directpo_rightSubDiv{
    height:70vh;
    overflow: scroll;
}
#emedhub_directPo_paper1 {
    max-height: 16.98vh;
    border-radius: 0.3vw;
    background-color: #FFFFFF;
    padding: 1vw;
    margin-bottom: 1.5vw;
}
.emedhub_directpo_subDiv{
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 1.5vw;
    margin: 1.5vw 2vw;
}
#emedhub_directpo_table{
    background-color: #FFFFFF;
    height:53.5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
}
.emedhub_directpoTable_Paper{
    height:53.5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
}
.emedhub_directpoTable_head{
    display: flex;
    flex: 1;
}
#emedhub_directpoTable_headTxt{
    background-color: #faf9f9;
    color: #616161;
    font-weight: 600;
    font-size: 0.9vw;
}
#emedhub_directpoTable_cell1{
    font-size: 0.9vw;
    font-weight: 600;
}
.emedhub_directpoTable_cell{
    color: #66686A;
}
.emedhub_directpo_RightHead {
    background-color: #faf9f9;
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.emedhub_directpo_RightHead_text {
    font-size: 0.9vw;
    font-weight: 600;
    margin-left: 1.5vw;
}
.emedhub_directpo_RightContentBox {
    background-color: #FFFFFF;
    padding: 1vw;
}
#emedhub_directpo_RightContentBox1{
    max-height: 53vh; 
}
#emedhub_directpo_RightContentBox2{
    max-height: 95vh;
}
.emedhub_directpo_RightContentSubDiv{
    display: flex;
    justify-content: space-between;
}
.emedhub_directpo_RightContent_TileBlock{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.emedhub_directpo_RightBtnCon {
    background-color: #faf9f9;
    height: 3vh;
    padding: "0.8vw";
}
.emedhub_confirmOrder_mainDiv{
    display:flex;
    flex-direction: column;
}
#emedhub_confirmOrder_subDiv1{
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-around;
    max-height: 16.98vh;
    padding:0.625vw;
}
#emedhub_confirmOrder_subDiv2{
    margin:1vw;
    height:35vh;
}
.emedhub_confirmOrder_Div{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.emedhub_vendorMonthly_paper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-height: 16vw;
    padding: 0.625vw;
}

.emedhub_vendorMonthly_mainBox {
    width: 100%;
    height: 80vh;
    padding: 1vw;
    background-Color: #f5f8f7;
}

.emedhub_vendorMonthly_subdiv {
    padding: 0.625vw;
}

.emedhub_vendorMonthly_title {
    font-size: 0.7vw;
}

#emedhub_ventorMonthly_Table {
    height: 38vh;
    background-color: #FFFFFF;
}
.emedhub_wantedListTable{
    height: 63vh;
   border-radius: 0.3vw;
   overflow: auto;
}
.emedhub_confirmOrder_Table{
    height: 30vh;
    border-radius: 0.3vw;
}
.emedhub_subloc_stkTranfer_Table{
    height: 35vh;
    border-radius: 0.3vw;
    overflow-y: scroll;
}
#emedhub_vendorMonthly_popupBox{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vw;
    max-height: 60vw;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
.emedhub_paymentdetail_subdiv{
    display:flex;
    flex:1
}
#emedhub_directpoRight_mainBox{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    max-height: 30vw;
    padding: 3vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
.emedhub_popupIcon_img{
    width: 1.3vw;
    height: 1.3vw;
    border-radius: 0.3vw;
}
.emedhub_popupIcon_img1{
    width: 1.7vw;
    height: 1.7vw;
    border-radius: 0.3vw;
}

.eMed_dir_del{
    width: 1.5vw;
    height: 1.5vw;
    cursor: pointer;
}

.eMed_pur_print{
    width: 2vw;
    height: 2vw;
}

#eMed_pro_date_picker{
    margin: 0.7vw;
}

.eMed_pur_epr{
    margin-bottom: 0.5vw;
    margin-left: 0.9vw;
}

.eMed_bat_font{
    font-weight: 700;
}

.eMed_pur_deliv_img{
    width: 4vw;
    height: 4vw;
    border-radius: 0.3vw;
}

.eMed_pur_ctry_txt{
    width: 41vw;
}

.eMed_pur_txt{
    width: 61vw;
}

.eMed_pur_back_color{
    background-color: #FFFFFF;
}

.eMed_pur_txt_field{
    margin: 1vw;
}

.eMed_pur_card{
    padding: 0.5vw;
}

.eMed_pur_txt_one{
    font-size: 0.95vw;
    font-weight: bold;
    padding: 0.3vw;
}

.eMed_pur_txt_two{
    font-size: 0.7vw;
    color: #66686A;
}

.eMed_pur_footer{
    margin-bottom: 0.5vw;
}

.eMed_pur_bill_txt{
    font-weight: bold;
    padding: 0.3vw;
}

.eMed_pur_pay_text{
    background-color: #FFFFFF;
    padding: 0.5vw;
}

#emedhub_vendorMonthly_popupBox_new{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-height: 65vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}