.eMed_Asset_Sts_log_style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48vw;
    height: 30vw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
}

.eMed_Asset_Sts_Cge_style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35vw;
    height: 20.5vw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
}