.eMed_Main_container {
    margin-top: 0.5vw;
    background-color: #F5F8F7;
}

.eMed_Top_Container {
    height: 13vh;
    display: flex;
    flex-direction: row;
}

.eMed_Table_Container {
    height: 65vh;
}

.eMed_Cards_Container {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Action_Container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1vw;
    padding-right: 1vw;
}

.eMed_action_img {
    height: 2vw;
    width: 2vw;
}

.eMed_action_img_small {
    height: 1vw;
    width: 1vw;
}

.eMed_Table_Wrapper {
    width: 92vw;
    height: 65vh;
    margin-left: 1vw;
    background-color: white;
}

.eMed_Filter_Icon_div {
    width: 4vw;
    /* border-right: 2px slategray solid; */
    /* margin-right: 1vw; */
}

.eMed_Filter_Billing_Container {
    width: 30vw;
    margin-top: 5vw;
}

.eMed_Filter_Top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px slategray solid;
    padding-bottom: 0.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
}

.eMed_Filter_TextFields {
    margin-left: 1vw;
    margin-right: 1vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.eMed_Filter_Header {
    height: 5vh;
    background-color: #FAF9F9;
    display: flex;
    align-items: center;
    margin-top: 0.5vw;
}

.eMed_CheckBox_Group {
    display: flex;
    flex-wrap: wrap;
    margin-left: 1vw;
}

.eMed_Filter_Btn_Div {
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1vw;
    margin-top: 1vw;
}

.eMed_Filter_Btns {
    text-transform: capitalize !important;
    margin-right: 1vw !important;
}

.eMed_Dropdown {
    width: 13.5vw;
    margin-top: 0.8vw !important;
}

.eMed_Filter_DateFilter {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 1vw;
    padding-left: 1vw;
}

.eMed_CheckBoxDiv {
    width: 9vw;
}

.eMed_Bill_Filter_TextFeild_Big {
    width: 27vw !important;
    margin-top: 0.8vw !important;
}

.eMed_Bill_Filter_TextFeild_small {
    width: 13.5vw !important;
    margin-top: 0.8vw !important;
}

.eMed_CreditPoP_TitleDiv {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_CrdPop_InputDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
    height: 3.5vw;
}

.eMed_CreditBill_ErrorTxt {
    color: red;
    font-size: 0.65vw !important;
    margin-top: 0.3vw !important;
}

.eMed_CrdPop_AmtTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0.5vw;
    margin-bottom: 1vw;
}

.eMed_CrdPop_Check_Group {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5vw;
}

.eMed_CrdPop_CheckBoxDiv {
    width: 5vw;
}

.eMed_CrdPop_Amount_TextFields {
    width: 16vw;
    margin-top: 0.7vw !important;
    background-color: #E4F2F0;
    padding: 0 !important;
}

.eMed_CrdPop_Denomi_div {
    background-color: #E4F2F0;
    height: 12vw;
    border-radius: 0.3vw;
    margin-top: 0.5vw;
}

.eMed_CrdPop_Denomi_div_New {
    background-color: #E4F2F0;
    height: 15vw;
    border-radius: 0.3vw;
    padding: 0.5vw;
    width:26vw
}

.eMed_CrdPop_Denomi_invidual_div {
    margin-left: 1.2vw;
    margin-top: 0.8vw;
    width: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_CrdPop_Denomi_MainGrid_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 1.2vw;
}

.eMed_crdPop_amountTextField {
    background-color: white;
    height: 1.5vw;
    padding-left: 0.5vw !important;
    padding-right: 0.5vw !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
    width: 5vw;
    border-radius: 0.3vw !important;
}

.eMed_popup_amount_label {
    margin-left: 1vw !important;
    font-size: 1.3vw !important;
}

.eMed_countsAmount_Label {
    width: 2.5vw !important;
    text-align: right;
}

.eMed_BillPreview_Main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    height: 40vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_BillPreview_Top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_BillPreview_Image {
    height: 33vw;
    border: 1px solid grey;
    margin-left: 1vw;
    margin-right: 1vw;
    border-radius: 0.26vw;
}

.eMed_BillPreview_BtnDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_Recpt_header_amt_card {
    min-width: 0vw !important;
    padding: 0vw !important;
    text-transform: capitalize !important;
}

.eMed_Summery_Cards_row {
    display: flex;
    flex-direction: row;
    margin-left: 1.5vw;
}

.eMed_Summery_Cards_wrapper {
    display: flex;
    flex-direction: row;
    width: 60vw;
    align-items: center;
    padding: 1.5vw;
}

.eMed_Cards_Receiptcon {
    max-width: 150vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
}

.eMed_Summary_Report_Loader_comp{
    height: 20vw;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_IP_Exp_printimg {
    width: 1.5vw;
    height: 1.5vw;
}

#eMed_IP_ExpPop_TltTxt {
    color: #616161;
    font-weight: 600;
}

.eMed_IP_ExpPop_MainDiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 39.8vw;
    background-color: white;
    border-radius: 5px;
    outline: none;
}

.eMed_IP_ExpPop_Header {
    height: 2vw;
    display: flex;
    justify-content: space-between;
    margin-left: 1vw;
    align-items: center;
    padding: 0.5vw;
}

.eMed_IP_ExpPop_wrapper {
    height: 33.9vw;
    padding: 0.5vw;
    overflow: auto;
}

.eMed_IP_ExpPop_btnBox {
    height: 4vw;
    padding: 0.5vw;
}

.eMed_IP_ExpPop_imgDiv {
    margin: 0.5vw;
    width: 27.9vw;
    height: 16.8vw;
}

.eMed_IP_ExpPop_img {
    width: 27.9vw;
    height: 16.8vw;
}

#eMed_IP_ExpPop_Icon {
    position: relative;
    bottom: 18vw;
    left: 26.8vw;
}

.eMed_IP_ExpPop_txtbox {
    margin: 0.5vw;
}

.eMed_IP_ExpPop_imgSubDiv {
    width: 14vw;
    height: 10vw;
    border: 1px dotted var(--gray);
    border-radius: 0.3vw;
}

#eMed_opip_billTxt{
    font-size: 0.9vw;
}

.eMed_split_Label_Amount{
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    width: 10vw;
    cursor: pointer;
}
.eMed_split_Wrap{
    display: flex;
    flex-direction: row;
    margin-left: 1vw;
    margin-top: 0.5vw;
}

.eMed_Crdtype_Header{
    display: flex;
    width: 30vw;
    align-items: center;
    justify-content: space-between;
    height: 7vw;
    padding-left: 1vw;
    padding-right: 1vw;
}