#eMed_expConfig_Container {
    margin-top: 1.5vw;
    padding: 0 0.625vw 0 0.625vw;
}
.eMed_expConfig_Box {
    height: 80vh;
}
.eMed_expConfig_Div {
    height: 75vh;
}
.eMed_expConfig_rightHead {
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.eMed_expConfig_rightHead_txt {
    font-weight: 600;
    margin-left: 1.5vw;
    color: #616161;
}
.eMed_expConfig_SubDiv {
    height: 65vh;
    padding: 0.5vw;
}
.eMed_expConfig_txtbox{
    margin: 1vw 0.625vw ;
}
#eMed_expConfig_rightBtnCon {
    height: 5vh;
    padding: "0.625vw";
    border-top-left-radius: 0vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}