.rad_bill_trns_container{
    height: 79vh;
}

.rad_bill_trns_header_card{
    display: flex;
    padding: 0.5vw 0vw;
}

.rad_bill_trns_cards{
    display: flex;
    flex: 0.7;
}

.rad_bill_trns_btns{
    flex: 0.3;
    display: flex;
    justify-content: flex-end;
    padding-right: 1vw;
    align-items: center;
}

.rad_bill_trns_content_card{
    padding: 0.5vw;
    height: 66vh;
}

.rad_bill_btn{
    height: 2vw;
}