:root {
    --gray: #616161;
    --white: #FFFFFF;
    --fill: #E4F2F0;
}
#eMed_AdHistory_Container {
    height: 80vh;
    padding: 0.625vw;
}
.eMed_AdHistory_Box {
    height: 78vh;
}
.eMed_AdHistory_header{
    height: 6.5vh;
}
.eMed_AdHistory_Div {
    width: 100%;
    height: 70vh;
    background-color: var(--white);
    overflow-y: auto;
}
#eMed_AdHistory_Accord{
    margin-bottom: 0.7vw;
    border-radius: 0.3vw;
}
#eMed_AdHistory_AccordTxt{
    font-weight: 600;
    font-size: 0.9vw;
    color:var(--gray);
    margin-left: 0.7vw;
}
#eMed_AdHistory_AccordDetails{
    height: fit-content;
}  
.eMed_AdHistory_Accord_MainTbl{
    height: 33vh;
}
.eMed_AdHistory_Accord_SubTbl_Div{
    height: 30vh;
}
.eMed_AdHistory_AccordTbl {
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.emed_billMedication_Box {
    height: 73vh;
}