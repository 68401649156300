.eMed_DOC_WebCam_Img_Div {
    height: 25vw;
    width: 33.2vw;
    margin-top: 1.5vw;
    margin-left: 1.4vw;
}

.eMed_sessions_div {
    border: 1px solid #BDBDBD;
    padding: 0.5vw;
    border-radius: 0.4vw;
    margin-left: 0.5vw;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 5.5vh;
    width: 15.5vw
}

.eMed_Sticky_first_Column {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 2; /* Adjust as needed to ensure it appears above other columns */
}

.eMed_button{
    color: #fff;
    padding: 0.25vw 1vw 0.25vw 1vw;
    border-radius: 0.2vw;
    cursor: pointer;
}