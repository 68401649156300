.rad_Rpts_container {
    height: 79vh;
}

.rad_rpts_header_card {
    padding: 0.5vw 0vw;
    display: flex;
}

.rad_rpts_cards {
    flex: 0.7;
    display: flex;
}

.rad_rpts_btns {
    flex: 0.3;
    display: flex;
    justify-content: flex-end;
    padding-right: 1vw;
    align-items: center;
}

.rad_rpts_content_card {
    height: 66vh;
    padding: 0.5vw;
}