.eMed_ModuleScreen_Wrapper {
    height: 85vh;
    width: 98%;
    /* border: 1px solid lightgray; */
    border-radius: 0.26vw;
    box-shadow: 5px;
    overflow-y: scroll;
}

.eMed_Proceed_Btn {
    display: flex;
    justify-content: center;
    margin-top: 1.5vw;
}

.eMed_Cards_Wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.5vw;
    max-height: 41vh;
    background-color: "red";
    overflow-y: scroll;
}
.eMed_Main_div { 
     max-height:80vh;
     overflow-y:scroll;
}