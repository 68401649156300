#emed_CalenderHead {
    display: flex;
    flex-direction: row;
    width: 100px;
}

#emed_CalenderClose_Icon {
    margin-left: "40%";
    margin-right: '15px';
}

#emed_Calender_SearchIcon {
    margin-left: 80%;
    margin-top: -12%;
}

#emed_Calender_TodayButton {
    margin-top: -5%;
    margin-left: 65%
}