.eMed_Canteen_Menuitem_Con {
    width: 94vw;
    height: 79vh;
}

.eMed_Canteen_Menuitem_LeftCon {
    width: 35vw;
    height: 76.2vh;
    background-color: white;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_Canteen_Menuitem_rightCon {
    width: 60vw;
    height: 76.2vh;
    background-color: white;
    margin: 1vw;
}

.eMed_Canteen_leftHeadCon {
    height: 6vh;
    background-color: #F5F8F7;
    display: flex;
    align-items: center;
    border: 1px solid #F5F8F7;
    border-radius: .3vw .3vw 0px 0px
}

.eMed_Canteen_Headtext {
    font-weight: 600 !important;
    color: #616161;
    padding-left: 1vw;
}

.eMed_Canteen_BtnCon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7vh;
    background-color: #F5F8F7;
    border: 1px solid #F5F8F7;
    border-radius: 0vw 0vw .3vw .3vw;
}

.eMed_Canteen_BtnText {
    width: 6vw;
    text-transform: capitalize !important;
}

.eMed_Canteen_TopCon {
    height: 13vh;
    display: flex;
    flex-direction: row;
    margin: .5vw 1vw 0vw 1vw;
}

.eMed_Canteen_SecondCon {
    height: 63.5vh;
    margin-top: .5vw;
    background-color: white;
    margin: .5vw 1vw 0vw 1vw;
}

.eMed_Canteen_CardCon {
    width: 88vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Canteen_FilterCon {
    width: 12vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.eMed_Canteen_img {
    height: 2vw;
    width: 2vw;
}

.eMed_Canteen_CloseImg {
    height: 1vw;
    width: 1vw;
}

.eMed_CanteenFilter_Head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px slategray solid;
    padding-bottom: 0.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    width: 28vw;
}

.eMed_Canteen_FilterBox {
    width: 30vw;
    margin-top: 5vw;
}

.eMed_Canteen_fil_content {
    width: 28vw;
    height: 76vh;
    margin: auto;
}

.eMed_Canteen_Date {
    margin-top: 1vw;
    width: 14.2vw;
    position: relative;
    left: 14.8vw;
}

.eMed_Canteen_fil_text {
    width: 28vw;
}

.eMed_Canteen_CheckBoxDiv {
    width: 8vw;
}

.eMed_Canteen_CardBox {
    width: 20vw;
    height: 3.5vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    margin-right: 1vw;
}