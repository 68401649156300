.eMed_Pat_Bill_Container{
    height: 74.5vh;
}

.eMed_Pat_Bill_Header{
    height: 8.5vh;
}

.eMed_Pat_Bill_content{
    height: 65vh;
}

.eMed_Pat_Bill_Table{
    width: 92vw !important;
    margin: 0.5vw auto;
    height: 30.5vw;
    background-color: white;
    box-shadow: 0px 0px 1px light;
    border: 1px solid lightgray;
    overflow-y: scroll;
}

.eMed_Pat_Bill_Tbl_Header{
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
    padding: 0.5vw !important;
    color: #616161 !important;
}

.eMed_Credit_bill_pop_Tbl_Header{
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
    padding: 0.1vw !important;
    color: #616161 !important;
}

.eMed_Credit_bill_pop_Tbl_Body{
    padding: 0.5vw !important;
}

.eMed_Pat_Bill_Tbl_Two{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}

.eMed_Pat_Tbl_one_style{
    padding: 0.5vw 0.5vw 0.5vw 0.5vw !important;
}

.eMed_Pat_tbl_two_btn{
    min-width: 0vw !important;
    width: 2vw;
    height: 2vw;
}

.eMed_Pat_Bill_img{
    width:1.5vw;
    height: 1.5vw;
}

.eMed_Pat_Bill_more {
    min-width: 0vw !important;
    position: relative;
    color: #616161 !important;
    width: 0.5vw;
}
.eMed_bill_View_card {
    height: 60vh;
    background-color: white;
    width: 50vw;
    box-shadow: 0px 0px 1px gray;
    /* margin-top: 8vw !important; */
}
.eMed_bill_view_table {
    height: 40vh !important;
}
.eMed_bill_view_tbl_footer {
    display: flex;
    justify-content: space-between;
    height: 2vw;
    width: 20vw;
    margin-right: 1vw;
}
.eMed_billview_table_footer {
    display: flex;
    border-top: 1px solid lightgray;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
}
