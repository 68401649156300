.rad_srv_container{
    height: 79vh;
    display: flex;
    gap: 0.5vw;
    padding: 0.5vw;
}

.rad_lft_container{
    border: 1px solid lightgray;
    flex: 0.65;
    background-color: white;
}

.rad_rgt_container{
    border: 1px solid lightgray;
    flex: 0.35;
    background-color: white;
}

.rad_rgt_header{
    height: 5vh;
    display: flex;
    align-items: center;
    padding-left: 0.5vw;
    justify-content: space-between;
}

.rad_rgt_btn{
    height: 2vw !important;
    margin-right: 1vw !important;
    text-transform: capitalize !important;
}

.rad_rgt_btn_card{
    display: flex;
    justify-content: center;
    align-items: center;
    height:6vh;
}

.rad_rgt_content{
    height: 65.5vh;
    padding: 0.5vw;
}

.rad_rgt_tbl_head{
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
}

.rad_lft_scd_tbl{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}

.rad_tst_container{
    height: 79vh;
    padding: 0.5vw;
}

.rad_tst_tmp_header{
    background-color: white;
    height: 8vh;
}

.rad_tst_tmp_content{
    background-color: white;
    margin-top: 1vw;
    height: 67vh;
}

.rad_srv_tmp_pap{
    display: flex;
    padding: 0.5vw;
    align-items: center;
}