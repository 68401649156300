.Billing_No_record {
    height: 10vw;
    width: 100%;
}

.Billing_Table_Container {
    flex-direction: row;
    display: flex;
    flex: 1;
    margin-top: 0vw;
    height: 28vh;
}

.Billing_Table_Wrapper {
    flex: 0.82;
    background-color: white;
}

.Billing_Table_Header {
    background-color: #FAF9F9;
    height: 1.4vh;
    width: 100%;
}

#Billing_table_Head_text {
    background-color: #FAF9F9;
    font-weight: 600;
    color: #616161;
}

.Billing_Table_body {
    background-color: white;
    display: flex;
    align-items: flex-start;
    flex: 1;
    width: 100%;
}

.eMed_quickBill_view3 {
    display: flex;
    flex-direction: row;
    padding-top: 0.3vw;
}

.eMed_quickBill_view3_sub1 {
    flex: 0.6;
    height: 15vh;
}

.eMed_quickBill_view3_sub2 {
    flex: 0.4;
    height: 15vh;
}

.eMed_quickBill_view4 {
    height: 12vh;
}

.eMed_quickBill_view5 {
    display: flex;
    flex-direction: row;
    height: 5vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.eMed_quickBill_Btn_div {
    display: flex;
    justify-content: flex-end;
    height: 5vh;
    align-items: center;
}

.eMed_Esstial_Fields_title {
    font-size: 1.5vw;
    padding-left: 2vw;
}

.eMed_Services_TextFields {
    width: 30vw !important;
    margin-left: 1vw !important;
    margin-top: 0.5vw !important;
}

.eMed_WebCamPop_Main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 37vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_WebCam_Header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_WebCam_Div {
    height: 25vw;
    width: 40vw;
    margin-top: 1.5vw;
}

.eMed_WebCam_Btn_div {
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
    margin-left: 8vw;
    margin-top: 1.5vw;
}

.eMed_WebCam_Img_Div {
    height: 25vw;
    width: 33.2vw;
    margin-top: 1.5vw;
    margin-left: 3.4vw;
}

.emedhub_icon_img_close {
    position: relative;
    top: -1vw;
    left: -0.3vw;
    width: 2vw;
    height: 2vw;
}

.emedhub_doc_image_size {
    display: flex;
    flex-direction: row;
}

.eMed_createapt_right2_field_img_inv {
    width: 3vw;
    height: 3vw;
    cursor: pointer;
}

.eMed_demo_dropdown_inputs {
    width: 12vw;
    height: 2.5vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
}

.eMed_demo_dropdown_inputs2 {
    width: 12vw;
    height: 2.5vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
    background-color: #F5F8F7;
}

.eMed_op_bill_tbl_Card {
    width: 59.8vw;
    margin-left: 0.9vw;
    height: 30vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    background-color: white;
}

.eMed_op_bill_del_btn {
    height: 1.5vw;
    min-width: 0vw !important;
    width: 1.5vw;
    position: relative;
    right: 0.5vw;
}

.eMed_op_bill_img {
    height: 1.5vw;
    width: 1.5vw;
}

.eMed_op_bill_tbl {
    height: 12vw;
    overflow: auto;
}

.eMed_op_bill_tbl_footer {
    height: 14.5vw;
    display: flex;
    flex-direction: column;
    border-top: 1px solid lightgray;
}

.eMed_op_bill_tbl_one {
    height: 8vw;
    display: flex;
}

.eMed_op_bill_tbl_two {
    height: 5.5vw;
    border-top: 1px solid lightgray;
}

.eMed_flex_one {
    flex: 0.65;
}

.eMed_flex_two {
    flex: 0.35;
    justify-content: space-between;
}

.eMed_payment_op_detail {
    display: flex;
    background-color: #FAF9F9;
}

.eMed_op_pay_one {
    flex: 0.5;
    padding: 0.5vw;
}

.eMed_op_pay_two {
    flex: 0.5;
}

.eMed_cor_txt {
    padding-left: 0.5vw;
}

.eMed_table_bottom {
    height: 3.2vw;
    display: flex;
    border-top: 1px solid lightgray;
    margin-top: -0.3vw;
}

.eMed_Tbl_btm_one {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Tbl_btm_two {
    flex: 0.4;
    display: flex;
    justify-content: flex-end;
}

.eMed_op_bill {
    height: 64vh;
}

.eMed_op_Bill_list_bill {
    height: 27.5vh;
    overflow: scroll;
}

.eMed_OP_bill_card {
    height: 62vh;
}

.eMed_OP_bill_table_card {
    height: 61vh;
    background-color: white;
    width: 61.5vw;
    margin: auto;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
}

.eMed_OP_bill_right_card {
    width: 30vw;
    height: 61vh;
    margin: auto;
    background-color: white;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
}

.eMed_OP_credit_Bill {
    height: 63.5vh;
}

.eMed_OP_bill_right {
    width: 29vw;
    height: 63vh;
    background-color: white;
    box-shadow: 0px 0px 1px gray;
    border-radius: 5px;
    margin: auto 0.8vw auto 0vw;
}

.eMed_OP_bill_table {
    height: 63vh;
    background-color: white;
    width: 62vw;
    margin: auto 0.8vw;
    box-shadow: 0px 0px 1px gray;
    border-radius: 5px;
}

.emed_createAppoint_Checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2vw;
    padding-top: 0.3vw;
}

.eMed_cor_font {
    font-size: 0.7vw;
    color: #616161;
    font-weight: 600;
}

.eMed_service_name {
    height: 7vw;
}

.eMed_TextField_Small_font {
    font-size: 0.5vw !important;
}

.eMed_Discount_Reason {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 15vw;
    background-color: white;
    overflow: scroll;
}

.eMed_Service_Discount {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32vw;
    height: 61vh;
    background-color: white;
    overflow: scroll;
}

.eMed_Unavailable_point{
    height: 1vw;
    width: 1vw;
    border-radius: 100%;
    margin-right: 0.5vw;
}